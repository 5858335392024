import React from 'react';
import Loader from 'react-loader-spinner'

const LoadingSpinner = props => {
    return ((props.isVisible) &&
        <div style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            lineHeight: "100vh",
            zIndex: "9999",
            backgroundColor: "lightGray",
            opacity: "0.4"
        }}>
            <Loader
                type="Rings"
                color="#000"
                height={100}
                width={100}
            />
        </div>)
}

export default LoadingSpinner;