import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist'
import { LOCALES } from '../i18n';

class LanguageStore {
    selectedLanguage = LOCALES.GERMEN;

    updateLanguage = (language) => {
        this.selectedLanguage = language;
    }
}

decorate(LanguageStore, {
    selectedLanguage: [persist, observable],
    updateError: action
})
var languageStore = new LanguageStore();
export default languageStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('languageStore', languageStore)
}

