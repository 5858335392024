import { decorate, observable, action } from "mobx";

class LoaderStore {
    isVisible = false

    showLoader = (isVisible) => {
        this.isVisible = isVisible
    }
}

decorate(LoaderStore, {
    isVisible: observable,
    showLoader: action
})

var loaderStore = new LoaderStore();
export default loaderStore; 