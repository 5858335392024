/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import { Offline, Online } from "react-detect-offline";
import { Helmet } from "react-helmet"
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { In18Provider } from '../../i18n';
import "../../assets/css/stylesheet.scss";
import Header from "./header";
import Footer from "./footer";
import NetworkErrorComponent from '../../components/error/networkError.component';
import LoadingSpinner from "./loader";
import loaderStore from '../../store/loaderStore'
import languageStore from '../../store/languageStore'
let dataLayer;
if (typeof window !== 'undefined') {
  dataLayer = window.dataLayer || [];
}
const Layout = observer(({ header, footer, isBackBtn, prevUrl, children }) => {
  useEffect(() => {
    dataLayer.push({ event: 'pageView' });
  }, []);
  const isHeader = () => {
    if (header) {
      return 'pt-80'
    } else {
      return ''
    }
  }
  return (
    <In18Provider locale={languageStore.selectedLanguage}>
      <Helmet>
        <title>bolttech Geräteversicherung</title>
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>
      <LoadingSpinner isVisible={loaderStore.isVisible}></LoadingSpinner>
      <Online polling={false}>
        {header && <Header isBackBtn={isBackBtn} prevUrl={prevUrl} />}
        <main className={isHeader()}>{children}</main>
        {footer && <Footer />}
      </Online>
      <Offline polling={false}>
        <Header />
        <NetworkErrorComponent />
      </Offline>
    </In18Provider>
  )
})

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout;