import React, { useState } from 'react';
import { navigate } from 'gatsby';
import logo from "../../assets/images/bolttech_logo_header_de.svg";
import { LOCALES } from '../../i18n';
import languageStore from '../../store/languageStore';

const Header = () => {
  const [isOpenDd, setOpenDd] = useState(false);

  const handleClick = () => {
    isOpenDd ? setOpenDd(false) : setOpenDd(true);
  }

  const languageSelector = false

  const changeLanguage = lang => {
    if (lang === LOCALES.ENGLISH) {
      languageStore.updateLanguage(LOCALES.ENGLISH)
    } else {
      languageStore.updateLanguage(LOCALES.GERMEN)
    }
  }

  return (
    <>
      <div className="header">
        <div className="container">
          <div onClick={() => navigate('/')} className="logo">
            <img src={logo} alt="bolttech" />
          </div>
          {languageSelector ? (
            <div
              className={
                isOpenDd ? "language-translate active" : "language-translate"
              }
              onClick={handleClick}
            >
              {languageStore.selectedLanguage === LOCALES.GERMEN ? "DE" : "EN"}
              {isOpenDd && (
                <ul className="lt-dd">
                  {languageStore.selectedLanguage === LOCALES.GERMEN && (
                    <li onClick={() => changeLanguage(LOCALES.ENGLISH)}>EN</li>
                  )}
                  {languageStore.selectedLanguage === LOCALES.ENGLISH && (
                    <li onClick={() => changeLanguage(LOCALES.GERMEN)}>DE</li>
                  )}
            </ul>
              )}
          </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  )
}

export default Header;